<template>
  <div>
    <section>
      <v-chip-group mandatory show-arrows active-class="primary--text" v-model="selectedCat" @click="getExamsByGroupId">
        <v-chip v-for="cat in categories" :key="cat.id">
          {{ cat.name }}
        </v-chip>

      </v-chip-group>

      <div v-if="items && items.length > 0">
        <v-card class="my-2 " v-for="(exam, index) in items" :key="index" flat hover ripple
                @click="openExamBottomSheet(exam, index)">
          <b-list-group>
            <b-list-group-item class="d-flex align-items-center">
              <v-avatar color="red lighten-5" size="48">
                <span class="red--text text--lighten-1 f18">{{ index + 1 }}</span>
              </v-avatar>
              <div class="d-flex flex-column ms-4 me-0 align-items-start justify-content-center">
                <span class="f14 font-weight-bold">{{ exam.name }}</span>
                <span class="f12 grey--text">{{ exam.questionCount }} {{ $t('Question') }}</span>
              </div>

              <v-btn :color="exam.bookmarked ? 'primary' : 'grey darken-4'" icon outlined class="ms-auto me-0"
                     elevation="0">
                <v-icon :class="exam.bookmarked ? 'primary--text' : 'grey--text text--darken-4'">mdi-dots-horizontal
                </v-icon>
              </v-btn>

            </b-list-group-item>
          </b-list-group>
        </v-card>
      </div>
      <span class="text-center f16 d-block mt-8" v-else>
        {{ $t('No results found') }}
      </span>

      <infinite-loading @infinite="infiniteHandler" :identifier="selectedCat">
        <div slot="spinner">
          <!-- {{ $t('Loading...') }} -->
          <v-icon>mdi-selection-ellipse spin</v-icon>
        </div>
        <div slot="no-more">
          <!-- {{ $t('No more items') }} -->
          <v-icon>mdi-access-point-off</v-icon>
        </div>
        <div slot="no-results">
          <!-- {{ $t('No results found') }} -->
          <v-icon>mdi-access-point-off</v-icon>
        </div>
      </infinite-loading>
    </section>

    <vue-bottom-sheet ref="examBottomSheet">
      <section class="d-flex flex-column align-items-center justify-content-center">
        <v-list rounded dense class="w-100">
          <span class="text-center mb-1 d-block f14 font-weight-bold">{{ selectedExam.name }}</span>
          <v-list-item @click="handleExamAssign">
            <v-list-item-icon>
              <iconly name="adduser" type="light" class="primary--text"/>
            </v-list-item-icon>
            <v-list-item-title tag="span">
              {{ $t('Assign to clients') }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item @click="handleExamBookmark">
            <v-list-item-icon>
              <iconly v-show="!selectedExam.bookmarked" name="bookmark" type="light" class="primary--text"/>
              <iconly v-show="selectedExam.bookmarked" name="bookmark" type="bold" :class="'primary--text'"/>
            </v-list-item-icon>
            <v-list-item-title tag="span" v-if="!selectedExam.bookmarked">
              {{ $t('Add to favorites') }}
            </v-list-item-title>
            <v-list-item-title tag="span" v-else>
              {{ $t('Remove from favorites') }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item @click="handleExamPreview">
            <v-list-item-icon>
              <iconly name="show" type="light" class="primary--text"/>
            </v-list-item-icon>
            <v-list-item-title tag="span">
              {{ $t('Preview') }}
            </v-list-item-title>
          </v-list-item>

          <v-btn block color="primary" @click="closeExamBottomSheet" class="mt-auto mb-2" rounded>{{ $t('Close') }}
          </v-btn>

        </v-list>
      </section>
    </vue-bottom-sheet>

    <vue-bottom-sheet ref="assignExamBottomSheet" v-if="resetedAssignForm">
      <v-form class="d-flex flex-column align-items-center justify-content-center w-100 h-100" ref="assignExamForm">
        <div class="w-100">
          <v-text-field class="w-100 border" solo flat :label="$t('Search...')" prepend-inner-icon="mdi-magnify"
                        v-model="searchText" @keyup.enter="querySearchFollowers" hide-details></v-text-field>
        </div>
        <v-radio-group v-model="selectedClient" mandatory color="primary" column class="w-100" v-if="!followersError">
          <v-radio v-for="(client, index) in followers" :key="index" ripple :value="client" class="ltr">
            <template v-slot:label>
              <v-list-item class="rtl">
                <v-list-item-avatar>
                  <v-img
                      :lazy-src="require('@/assets/images/profile-circle.png')"
                      :src="client.profilePicture != null && client.profilePicture !== '' ? client.profilePicture : require('@/assets/images/profile.jpg')">
                  </v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title v-text="`${client.firstName} ${client.lastName}`"></v-list-item-title>
                </v-list-item-content>

              </v-list-item>
            </template>
          </v-radio>
        </v-radio-group>

        <div v-else class="my-4">
          <span>{{ followersErrorText }}</span>
        </div>

        <div class="w-100 mt-auto ">
          <v-text-field v-mask="currencyMask" class="text-center" outlined light single-line v-model="price"
                        :disabled="isFree"
                        :key="keyPrice" :placeholder="$t('Exam price')" :suffix="$t('RIAL')" type="text"
                        :rules="[(v) => !!v]"
                        required
                        hide-details/>
        </div>

        <div class="w-100">
          <v-checkbox class="w-100" v-model="isFree" :label="$t('Free')"></v-checkbox>
        </div>
        <div class="my-2 w-100">
          <v-btn block color="primary" @click="submitAssign" rounded>{{ $t('Submit Exam') }}</v-btn>
        </div>
      </v-form>
    </vue-bottom-sheet>


  </div>
</template>
<script>
import apiService from "@/http/ApiService";
import moment from "moment-jalaali";
import fa from "moment/locale/fa";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

moment.locale("fa", fa);
moment.loadPersian();

const currencyMask = createNumberMask({
  prefix: '',
  allowDecimal: false,
  includeThousandsSeparator: true,
  allowNegative: false,
});

export default {
  components: {},
  data() {
    return {
      currencyMask,
      keyPrice: 0,
      isFree: false,
      price: '',
      resetedAssignForm: false,

      categories: [],
      selectedCat: 0,
      selectedExam: {},
      selectedExamPostition: -1,

      searchText: null,
      followers: [],
      followerPage: 1,
      followerSize: 12,
      followersError: false,
      followersErrorText: "",

      selectedClient: null,

      items: [],
      page: 1,
      size: 12,
    };
  },
  methods: {
    openExamBottomSheet(exam, index) {
      this.resetedAssignForm = true
      this.$refs.examBottomSheet.open();
      this.selectedExam = exam;
      this.selectedExamPostition = index;
    },
    closeExamBottomSheet() {
      this.$refs.examBottomSheet.close();
      this.selectedExam = {};
      this.selectedExamPostition = -1;
      this.resetExamBottomSheet();
    },
    resetExamBottomSheet() {
      this.selectedClient = null;
      this.isFree = false;
      this.price = '';
    },
    closeAssignExamBottomSheet() {
      this.resetAssignExamBottomSheet();
      this.selectedExam = {};
      this.selectedExamPostition = -1;
      this.resetedAssignForm = false
      this.$refs.assignExamBottomSheet.close();
    },
    resetAssignExamBottomSheet() {
      this.selectedClient = null;
      this.isFree = false;
      this.price = '';
    },
    getAllCategories() {
      apiService.getCategoryExamList(0, 200)
          .then((response) => {
            this.categories = response.data.content;
            var first = {
              id: -1,
              name: "همه"
            };

            this.categories.unshift(first)
          })
          .catch((err) => {
            this.$swal({
              icon: "warning",
              text: err ? err.response.data.detail : this.$t("Error"),
              confirmButtonText: this.$t('OK'),
            })
                .then((result) => {
                  if (result.isConfirmed) {
                    this.$router.go(-1);
                  }
                });

          });
    },
    getExamsByGroupId(page, size) {
      apiService.getExamsByGroupId(this.categories[this.selectedCat].id, page, size)
          .then((response) => {
            this.items = response.data.content;
          })
          .catch((err) => {
            this.$swal({
              icon: "warning",
              text: err ? err.response.data.detail : this.$t("Error"),
              confirmButtonText: this.$t('OK'),
            })
                .then((result) => {
                  if (result.isConfirmed) {
                    this.$router.go(-1);
                  }
                });

          });
    },
    getAllExams(page, size) {
      apiService.getAllExams(page, size)
          .then((response) => {
            this.items = response.data.content;
          })
          .catch((err) => {
            this.$swal({
              icon: "warning",
              text: err ? err.response.data.detail : this.$t("Error"),
              confirmButtonText: this.$t('OK'),
            })
                .then((result) => {
                  if (result.isConfirmed) {
                    this.$router.go(-1);
                  }
                });

          });
    },
    infiniteHandler($state) {
      if (this.selectedCat == 0) {
        apiService.getAllExams(this.page, this.size)
            .then((response) => {
              if (response.data.content.length) {
                response.data.content.forEach(element => {
                  this.items.push(element);
                });

                this.page += 1;
                $state.loaded();
              } else {
                $state.complete();
              }
            })
            .catch((err) => {
              console.log(err);
              $state.error();
            });
      } else {
        apiService.getExamsByGroupId(this.categories[this.selectedCat].id, this.page, this.size)
            .then((response) => {
              if (response.data.content.length) {
                response.data.content.forEach(element => {
                  this.items.push(element);
                });

                this.page += 1;
                $state.loaded();
              } else {
                $state.complete();
              }
            })
            .catch((err) => {
              console.log(err);
              $state.error();
            });
      }
    },
    handleExamAssign() {
      this.$refs.examBottomSheet.close();
      if (this.selectedExam.bookmarked) {
        this.getAllFollowers(0, 50);
      } else {
        this.$swal({
          icon: "warning",
          text: this.$t("Please add the exam to your favorites before sending it to client"),
          confirmButtonText: this.$t('OK'),
        });
      }
    },
    getAllFollowers(page, size) {
      apiService.getAllFollowers(page, size)
          .then((response) => {
            if (response && response.data && response.data.content && response.data.content.length) {
              this.followersError = false;
              this.followers = response.data.content;

              setTimeout(() => {
                this.$refs.assignExamBottomSheet.open();
              }, 100);
            } else {
              this.followersError = true;
              this.followersErrorText = this.$t('You have no followers. The client must follow you to submit the exam');
              setTimeout(() => {
                this.$refs.assignExamBottomSheet.open();
              }, 100);
            }
          })
          .catch(() => {
            this.followersError = true;
            this.followersErrorText = this.$t('You have no followers. The client must follow you to submit the exam');
            setTimeout(() => {
              this.$refs.assignExamBottomSheet.open();
            }, 100);
          });
    },
    querySearchFollowers() {
      if (this.searchText) {
        this.followers = [];
        this.$refs.assignExamBottomSheet.close();
        apiService.querySearchFollowers(this.searchText)
            .then((response) => {
              if (response && response.data && response.data.length) {
                this.followersError = false;
                this.followers = response.data;
                setTimeout(() => {
                  this.$refs.assignExamBottomSheet.open();
                }, 100);
              } else {
                this.followersError = true;
                this.followersErrorText = this.$t('No results found');
                setTimeout(() => {
                  this.$refs.assignExamBottomSheet.open();
                }, 100);
              }
            })
            .catch(() => {
              this.followersError = true;
              this.followersErrorText = this.$t('Search error');
              setTimeout(() => {
                this.$refs.assignExamBottomSheet.open();
              }, 100);
            });
      } else {
        this.getAllFollowers(0, 50)
      }
    },
    submitAssign() {
      let examAssignDTO;
      if (this.selectedClient == null || this.selectedClient === {}) {
        this.$swal({
          icon: "warning",
          text: this.$t("No client selected"),
          confirmButtonText: this.$t('OK'),
        });
        this.$refs.assignExamForm.reset()
        this.closeAssignExamBottomSheet()
      } else {
        if (!this.isFree) {
          if (this.price !== null && this.price !== '') {
            examAssignDTO = {
              userUsername: this.selectedClient.mobileNumber,
              examFavoriteId: this.selectedExam.examFavoriteId,
              examId: this.selectedExam.id,
              price: parseFloat(this.price.replace (/,/g, "")),
            };
            apiService.examAssignToUser(examAssignDTO)
                .then(() => {
                  this.$swal({
                    icon: "success",
                    text: this.$t('Exam assined to client'),
                    confirmButtonText: this.$t('OK'),
                  });
                  this.$refs.assignExamForm.reset()
                  this.closeAssignExamBottomSheet()
                })
                .catch((err) => {
                  this.$swal({
                    icon: "warning",
                    text: err ? err.response.data.detail : this.$t("Error"),
                    confirmButtonText: this.$t('OK'),
                  });
                  this.$refs.assignExamForm.reset()
                  this.closeAssignExamBottomSheet()
                });
          } else {
            this.$swal({
              icon: "warning",
              text: this.$t("Please enter the price of the exam or check for free"),
              confirmButtonText: this.$t('OK'),
            })
            this.$refs.assignExamForm.reset()
            this.closeAssignExamBottomSheet()
          }
        } else {
          examAssignDTO = {
            userUsername: this.selectedClient.mobileNumber,
            examFavoriteId: this.selectedExam.examFavoriteId,
            examId: this.selectedExam.id,
            price: 0,
          };
          apiService.examAssignToUser(examAssignDTO)
              .then(() => {
                this.$swal({
                  icon: "success",
                  text: this.$t('Exam assined to client'),
                  confirmButtonText: this.$t('OK'),
                });
                this.$refs.assignExamForm.reset()
                this.closeAssignExamBottomSheet()
              })
              .catch((err) => {
                this.$swal({
                  icon: "warning",
                  text: err ? err.response.data.detail : this.$t("Error"),
                  confirmButtonText: this.$t('OK'),
                });
                this.$refs.assignExamForm.reset()
                this.closeAssignExamBottomSheet()
              });
        }
      }

    },
    handleExamBookmark() {
      if (this.selectedExam.bookmarked) {
        apiService.examDeleteFromFavorite(this.selectedExam.examFavoriteId)
            .then(() => {
              this.$refs.examBottomSheet.close();
              this.$swal({
                icon: "success",
                text: this.$t('The exam was removed from the favorites'),
                confirmButtonText: this.$t('OK'),
              });
              this.items[this.selectedExamPostition].bookmarked = false;
            })
            .catch((err) => {
              this.$refs.examBottomSheet.close();
              this.$swal({
                icon: "error",
                text: err ? err.response.data.detail : this.$t("Error"),
                confirmButtonText: this.$t('OK'),
              });
            });
      } else {
        var examFavoriteDTO = {
          drUsername: this.$store.getters.doctorId,
          examId: this.selectedExam.id,
          price: 0
        };
        apiService.examAddToFavorite(examFavoriteDTO)
            .then((response) => {
              this.$refs.examBottomSheet.close();
              this.$swal({
                icon: "success",
                text: this.$t('The exam was added to the favorites'),
                confirmButtonText: this.$t('OK'),
              });

              this.items[this.selectedExamPostition].bookmarked = true;
              this.items[this.selectedExamPostition].examFavoriteId = response.data.id;
            })
            .catch((err) => {
              this.$refs.examBottomSheet.close();
              this.$swal({
                icon: "error",
                text: err ? err.response.data.detail : this.$t("Error"),
                confirmButtonText: this.$t('OK'),
              });
            });
      }
    },
    handleExamPreview() {
      this.$refs.examBottomSheet.close();
      this.$router.push(`/s/exams/preview/${this.selectedExam.id}`);
    },
  },
  beforeMount() {
    var menu = [
      {
        title: "New request",
        to: "/s/exams/new",
        click: '',
      },
      {
        title: "Exam history",
        to: "/s/exams/history",
        click: '',
      },
    ];
    this.$store.commit('update_backActive', true);
    this.$store.commit('update_helloActive', false);
    this.$store.commit('update_threeDotsActive', true);
    this.$store.commit('update_threeDotsMenu', menu);
    this.$store.commit('update_notificationActive', false);
    this.$store.commit('update_toolbarTitleActive', true);
    this.getAllCategories();
    this.getAllExams(0, this.size);
  },
  watch: {
    selectedCat: function () {
      this.page = 1;
      if (this.selectedCat == 0) {
        this.getAllExams(0, this.size);
      } else {
        this.getExamsByGroupId(0, this.size);
      }
    },
  },
};
</script>